import i18next from "i18next";
import moment from "moment";
import { matchPath } from "react-router-dom";

import { Language } from "./language";
import "moment/locale/de";
import "moment/locale/en-gb";

export function joinUrl(url: string, data: any) {
  let final: string = url;

  // eslint-disable-next-line guard-for-in
  for (const property in data) {
    const value = data[property];
    final = final.replace(`/:${property}`, `/${value}`);
    final = final.replace(`$:${property}`, `$${value}`);
  }

  return final;
}

export function languageFromValue(value: string): Language {
  const result = Object.values(Language).filter(
    (key) => key.toString() === value
  );
  return result[0] ?? Language.DEFAULT;
}

export function changeLanguage(lng: Language) {
  // import locale for moment.js
  // when it's done then change 18next language
  // when this is done resolve
  // when anything fails, resolve anyway - TODO - it should revert to original and inform the user
  return new Promise<Language>((resolve) => {
    console.log("going to set language to:", lng);

    const afterMoment = () => {
      moment.locale(lng);
      if (i18next.language !== lng) {
        i18next
          .changeLanguage(lng)
          .then(() => resolve(languageFromValue(i18next.language)))
          .catch((error) => {
            console.warn("could not change 18next language to:", lng, error);
            return resolve(languageFromValue(i18next.language));
          });
      } else {
        return resolve(languageFromValue(i18next.language));
      }

      return null;
    };

    const onMomentImport = () => {
      console.log("moment locale imported successfully for lang:", lng);
      moment.locale(lng);
      afterMoment();
    };

    const onMomentImportError = (error: string) => {
      console.warn("could not import moment/locale for lang:", lng, error);
      afterMoment();
    };

    const availableMomentLocales = moment.locales();

    switch (lng) {
      case Language.DE:
        if (!availableMomentLocales.includes("de")) {
          // import('moment/locale/de').then(onMomentImport).catch(onMomentImportError)
          onMomentImport();
        } else {
          afterMoment();
        }
        break;
      // case Language.SK:
      //   if (!availableMomentLocales.includes('sk')) {
      //     import('moment/locale/sk').then(onMomentImport).catch(onMomentImportError)
      //   } else {
      //     afterMoment()
      //   }
      //   break
      default:
        if (!availableMomentLocales.includes("en-gb")) {
          onMomentImport();
          // TODO
          // import('moment/locale/en-gb').then(onMomentImport).catch(onMomentImportError)
        } else {
          afterMoment();
        }
    }
  });
}

export function matchUrl(path: string, pathname: string): boolean {
  const m = matchPath(pathname, {
    path,
    exact: false,
    strict: false,
  });

  return !!m;
}

export function parseUrl(url: string) {
  const query: any = {};
  const pairs = (url[0] === "?" ? url.substr(1) : url).split("&");
  for (let i = 0; i < pairs.length; i += 1) {
    const pair = pairs[i].split("=");
    const decodedParam = decodeURIComponent(pair[0]);
    const decodedValue = decodeURIComponent(pair[1]);
    query[decodedParam] = decodedValue;
  }
  return query;
}

export const parseUrlParam = (url: string, parameter: string) => {
  const paramValue = parseUrl(url)[parameter];
  if (paramValue) {
    return String(paramValue);
  }
  return undefined;
};
