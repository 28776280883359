import { createAuthApi } from "../../api/AuthApi";
import createCompanyApi from "../../api/CompanyApi";
import createCustomerApi from "../../api/CustomerApi";
import AuthResponse from "../../api/dto/auth/AuthResponse";
import RefreshResponse from "../../api/dto/auth/RefreshResponse";
import LevelDto from "../../api/dto/level/LevelDto";
import createLevelApi from "../../api/LevelsApi";
import { doGet } from "../../api/restApi";
import { changeLanguage, languageFromValue } from "../../enums/AppUtils";
import { Language } from "../../enums/language";
import Company from "../../model/Company";
import CurrentUser from "../../model/CurrentUser";

export enum AuthActionType {
  LOGIN_REQUEST = "LOGIN_REQUEST",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_ERROR = "LOGIN_ERROR",
  AUTHENTICATE_SUCCESS = "AUTHENTICATE_SUCCESS",

  REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS",
  REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR",

  PROFILE_REQUEST = "PROFILE_REQUEST",
  PROFILE_SUCCESS = "PROFILE_SUCCESS",
  PROFILE_ERROR = "PROFILE_ERROR",
  HISTORY_DATE = "HISTORY_DATE",
  SIDEBAR = "SIDEBAR",

  LANGUAGE = "LANGUAGE",
  COMPANY = "COMPANY",
  CUSTOMER = "CUSTOMER",
  LEVEL = "LEVEL",

  INITIALIZE_LANGUAGE_SUCCESS = "INITIALIZE_LANGUAGE_SUCCESS",
  INITIALIZE_LANGUAGE_ERROR = "INITIALIZE_LANGUAGE_ERROR",

  LOGOUT = "LOGOUT",
}

let refreshInterval: any;

type AuthActionPayload = {
  authResponse?: AuthResponse;
  user?: CurrentUser;
  language?: Language;

  selectedCustomerId?: string;
  selectedCompanyId?: string;
  selectedLevelId?: string;
  sidebarView?: "open" | "close";

  selectedHistoryDate?: Date;

  companies?: Company[];
  levels?: LevelDto[];
};

export interface AuthAction {
  type: AuthActionType;
  payload?: AuthActionPayload;
  error?: string;
}

const refreshToken = async (
  dispatch: (action: AuthAction) => void
): Promise<RefreshResponse | undefined> => {
  const authApi = createAuthApi();
  console.info("refresh token");
  try {
    const refresh = await authApi.refresh();
    dispatch({ type: AuthActionType.REFRESH_TOKEN_SUCCESS });
    return refresh;
  } catch (e) {
    console.error(`error refresh token: ${e}`);
    dispatch({ type: AuthActionType.REFRESH_TOKEN_ERROR });
  }
  return Promise.resolve(undefined);
};

export const doLogOut = async (
  dispatch: (action: AuthAction) => void
): Promise<void> => {
  const authApi = createAuthApi();

  console.info("doLogOut");

  clearInterval(refreshInterval);

  try {
    await authApi.logout();
    dispatch({ type: AuthActionType.LOGOUT });
  } catch (e) {
    console.error("api logout not successfull");
    dispatch({ type: AuthActionType.LOGOUT, error: e });
  }

  return Promise.resolve();
};

export async function doAuthenticate(
  dispatch: (action: AuthAction) => void
): Promise<RefreshResponse | undefined> {
  const res = await refreshToken(dispatch);

  clearInterval(refreshInterval);
  // start refreshing token
  if (res) {
    refreshInterval = setInterval(() => {
      console.info("start refresh token");
      refreshToken(dispatch);
    }, res.accessTokenExpiresIn - 10);
  }

  dispatch({
    type: AuthActionType.AUTHENTICATE_SUCCESS,
  });

  return res;
}

export const doChangeLanguage = async (
  dispatch: (action: AuthAction) => void,
  language: Language
) => {
  const changedLanguage = await changeLanguage(language);
  dispatch({
    type: AuthActionType.LANGUAGE,
    payload: { language: changedLanguage },
  });
};

export async function doLoginUser(
  dispatch: (action: AuthAction) => void,
  loginPayload: { username: string; password: string }
): Promise<boolean> {
  const authApi = createAuthApi();

  dispatch({ type: AuthActionType.LOGIN_REQUEST });

  clearInterval(refreshInterval);

  try {
    // login result is access and refresh token in cookies
    // access token is short lived (expiration in response)
    // refresh token (long lived) is used to generate new access token
    const authDto = await authApi.login(loginPayload);

    // start refreshing token
    refreshInterval = setInterval(() => {
      console.info("start refresh token");
      refreshToken(dispatch);
    }, authDto.accessTokenExpiresIn - 10);

    dispatch({
      type: AuthActionType.LOGIN_SUCCESS,
      payload: { authResponse: authDto },
    });

    return true;
  } catch (error) {
    dispatch({ type: AuthActionType.LOGIN_ERROR, error });
    return false;
  }

  //   const response = await authAPI.doLogin(loginPayload);
  //
  //   if (response.data.token) {
  //     dispatch({
  //       type: AuthActionType.LOGIN_SUCCESS,
  //       payload: { token: response.data },
  //     });
  //     localStorage.setItem("app.token", response.data.token);
  //     return true;
  //   }
  //
  //   dispatch({ type: AuthActionType.LOGIN_ERROR, error: "login error" });
  // } catch (error) {
  //   dispatch({ type: AuthActionType.LOGIN_ERROR, error });
  // }

  return false;
}

export const doInitialize = async (
  language: string,
  dispatch: (action: AuthAction) => void
) => {
  const lng = languageFromValue(language);

  // change the language or the libs (i18next, moment and others)
  await changeLanguage(lng);

  dispatch({
    type: AuthActionType.INITIALIZE_LANGUAGE_SUCCESS,
    payload: { language: lng },
  });
};

export async function doLoadProfile(
  dispatch: (action: AuthAction) => void
): Promise<void> {
  const companyApi = createCompanyApi();
  const customerApi = createCustomerApi();
  const levelApi = createLevelApi();

  try {
    const user = await doGet<CurrentUser>("v1/user");

    // select first company, later from profile
    let companies: Company[] = [];
    let selectedCompanyId: string | undefined;

    try {
      const companiesPage = await companyApi.getAll();
      if (companiesPage.content.length > 0) {
        selectedCompanyId = companiesPage.content[0].id;
      }
      companies = companiesPage.content;
    } catch (e) {
      console.error("cannot get companies");
    }

    // select first customer
    const customersPage = await customerApi.getAll();
    let selectedCustomerId: string | undefined;
    if (customersPage.content.length > 0) {
      selectedCustomerId = customersPage.content[0].id;
    }
    const customers = customersPage.content;

    // levels
    // select first level
    let levels: LevelDto[] = [];
    let selectedLevelId: string | undefined;
    try {
      const levelPage = await levelApi.getAll();
      if (levelPage.content.length > 0) {
        selectedLevelId = levelPage.content[0].id;
      } else {
        selectedLevelId = "all-levels";
      }
      levels = levelPage.content;
    } catch (e) {
      console.error("cannot get companies");
    }

    // Initial history date
    const today = new Date();

    dispatch({
      type: AuthActionType.PROFILE_SUCCESS,
      payload: {
        user,
        selectedCompanyId,
        selectedCustomerId,
        selectedHistoryDate: today,
        selectedLevelId,
        companies,
        levels,
      },
    });
  } catch (e) {
    console.error(`cannot load companies: ${e}`);

    dispatch({ type: AuthActionType.PROFILE_ERROR, error: e });
  }

  return Promise.resolve();
}

export const doSelectCompany = (
  dispatch: (action: AuthAction) => void,
  companyId: string
) => {
  dispatch({
    type: AuthActionType.COMPANY,
    payload: { selectedCompanyId: companyId },
  });
};

export const doSelectCustomer = (
  dispatch: (action: AuthAction) => void,
  customerId: string
) => {
  dispatch({
    type: AuthActionType.CUSTOMER,
    payload: { selectedCustomerId: customerId },
  });
};

export const doSelectLevel = (
  dispatch: (action: AuthAction) => void,
  levelId: string
) => {
  dispatch({
    type: AuthActionType.LEVEL,
    payload: { selectedLevelId: levelId },
  });
};

export const doSelectHistoryDate = (
  dispatch: (action: AuthAction) => void,
  date: Date
) => {
  dispatch({
    type: AuthActionType.HISTORY_DATE,
    payload: { selectedHistoryDate: date },
  });
};

export const doToggleSidebar = (
  dispatch: (action: AuthAction) => void,
  sidebarView: "open" | "close"
) => {
  dispatch({
    type: AuthActionType.SIDEBAR,
    payload: { sidebarView },
  });
};
