export default class RoutesConstants {
  static ROOT_ROUTE = "/";

  static LOGIN = "/login/:customerId/:seminarEventId";

  static EMAIL_VERIFY = "/ve";

  static USER_REGISTRATION = "/registration/:customerId/:seminarEventId";

  static PASSWORD_RESET = "/password-reset";

  static PASSWORD_CHANGE = "/pch";

  static LAYOUT = "/layout";

  static L_CUSTOMERS = "/layout/customers";

  static L_CUSTOMERS_ADD = "/layout/customers/add";

  static L_CUSTOMERS_EDIT = "/layout/customers/edit/:id";

  static L_COMPANIES = "/layout/companies";

  static L_COMPANIES_ADD = "/layout/companies/add";

  static L_COMPANIES_EDIT = "/layout/companies/edit/:id";

  static L_COSTS = "/layout/costs";

  static L_EMPLOYEES = "/layout/employees";

  static L_EMPLOYEES_ADD = "/layout/employees/add";

  static L_EMPLOYEES_EDIT = "/layout/employees/edit/:id";

  static L_LEADING_SYSTEM = "/layout/leading-system";

  static L_SEMINARS = "/layout/seminars";

  static L_SEMINARS_ADD = "/layout/seminars/add";

  static L_SEMINARS_EDIT = "/layout/seminars/edit/:id";

  static L_SEMINAR_EVENT_PARTICIPANTS = "/layout/seminar-event-participants";

  static L_SEMINAR_EVENT_PARTICIPANTS_ADD =
    "/layout/seminar-event-participants/add";

  static L_SEMINAR_EVENT_PARTICIPANTS_EDIT =
    "/layout/seminar-event-participants/edit/:id";

  static L_PERMISSIONS = "/layout/permissions";

  static L_PERMISSIONS_ADD = "/layout/permissions/add";

  static L_PERMISSIONS_EDIT = "/layout/permissions/edit/:id";

  static L_USERS = "/layout/users";

  static L_USERS_ADD = "/layout/users/add";

  static L_USERS_EDIT = "/layout/users/edit/:id";

  static L_USER_PROFILE_EDIT = "/layout/user-profile/edit/";

  static L_SEMINAR_EVENT_REGISTRATION = "/layout/seminar-events-registration";

  static L_USER_SEMINAR_EVENT_REGISTRATION =
    "/layout/user-seminar-events-registration/:id";

  static L_TRAVEL_EXPENSES_LIST = "/layout/travel-expenses";

  static L_TRAVEL_RATES_LIST = "/layout/travel-rates";

  //* *** OPEN, PUBLIC SPACE ***/
  static OPEN_LAYOUT = "/open";

  static OL_SEMINAR_EVENTS = "/open/seminar-events/:id";

  static OL_SEMINAR_EVENTS_LINK =
    "/open/preview/:seminarEventId/seminar-events/:customerId";
}
