import { createCRUDApi, CRUDApi } from "./apiCalls";
import CustomerDto from "./dto/customer/CustomerDto";
import CustomerDtoUpdate from "./dto/customer/CustomerDtoUpdate";

export default function createCustomerApi(): CRUDApi<
  CustomerDto,
  CustomerDtoUpdate,
  unknown
> {
  return createCRUDApi<CustomerDto, CustomerDtoUpdate, unknown>("v1/customers");
}
