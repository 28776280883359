import React from "react";

import { ImpulseSpinner } from "react-spinners-kit";

import { colors } from "../../enums/colors";

interface LoaderProperties {
  color?: string;
  size?: number;
}

const Loader = ({ color, size }: LoaderProperties) => (
  <div className="loader" style={{ minHeight: "24px" }}>
    <ImpulseSpinner
      frontColor={color}
      backColor={colors.GRAY_100}
      size={size}
      sizeUnit="px"
    />
  </div>
);

Loader.defaultProps = {
  color: colors.PRIMARY_75,
  size: 32,
};

export default Loader;
