import React, { Suspense, useEffect } from "react";

import { toast } from "react-toastify";

import "./App.scss";
import ApplicationRouter from "./components/application-router/ApplicationRouter";
import PageLoader from "./components/loader/PageLoader";
import { doInitialize } from "./context/auth/AuthAction";
import AuthProvider from "./context/auth/AuthProvider";
import useAuthState from "./hooks/auth";
import setupI18n from "./i18n";

// configure toast
toast.configure({
  hideProgressBar: true,
  position: "top-center",
  draggable: false,
  className: "custom-toast-container",
  autoClose: 5000,
  closeButton: (
    <>
      <i className="icon-close" />
    </>
  ),
});

function AppContent() {
  const { state, dispatch } = useAuthState();

  useEffect(() => {
    if (!state.initialized) {
      setupI18n(async (language) => {
        await doInitialize(language, dispatch);
      });
    }
  }, [state.initialized]);

  return <div>{state.initialized && <ApplicationRouter />}</div>;
}

function App(): JSX.Element {
  return (
    <div>
      <AuthProvider>
        <Suspense fallback={<PageLoader />}>
          <AppContent />
        </Suspense>
      </AuthProvider>
    </div>
  );
}

export default App;
