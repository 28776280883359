import { parse as parseProperties } from "dot-properties";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

import AppConstants from "./enums/AppConstants";
import { Language } from "./enums/language";

export default function setupI18n(callback: (lng: string) => void) {
  i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init(
      {
        fallbackLng: Language.DEFAULT,
        debug: true,

        detection: {
          order: ["querystring", "localStorage", "navigator", "htmlTag"],
          lookupQuerystring: "lng",
          lookupLocalStorage: AppConstants.LS_USER_LANGUAGE,
          caches: ["localStorage"],
        },

        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },

        load: "currentOnly",

        backend: {
          loadPath: "/localization/locale_{{lng}}.properties",
          parse: (data: any) => parseProperties(data, true),
        },

        react: {
          wait: true,
          useSuspense: true,
        },
      },
      () => {
        console.info(
          "i18n :: i18next initialized -> going to initialize the rest with language:",
          i18n.language
        );
        callback(i18n.language);
      }
    );
}
