import { set } from "lodash";

import ResourceId from "./dto/ResourceId";
import Page from "./Page";
import PageToLoad from "./PageToLoad";
import { doDelete, doGet, doPost, doPut } from "./restApi";

export interface CRUDApi<T, U, F> {
  get: (id: string) => Promise<T>;
  getAll: (
    pageToLoad?: PageToLoad,
    filter?: F,
    sort?: SortAttribute[]
  ) => Promise<Page<T>>;
  create: (dtoU: U) => Promise<ResourceId>;
  update: (id: string, dtoU: U) => Promise<void>;
  delete: (id: string) => Promise<void>;
}

export interface SortAttribute {
  key: string;
  sort?: "asc" | "desc";
}

export function createCRUDApi<T, U, F>(apiPath: string): CRUDApi<T, U, F> {
  return {
    create(dtoCreate: U): Promise<ResourceId> {
      return doPost<ResourceId>(apiPath, dtoCreate);
    },
    delete(id: string): Promise<void> {
      return doDelete(`${apiPath}/${id}`);
    },
    get(id: string): Promise<T> {
      return doGet<T>(`${apiPath}/${id}`);
    },
    getAll(
      pageToLoad?: PageToLoad,
      filter?: F,
      sort?: SortAttribute[]
    ): Promise<Page<T>> {
      return getAll<T, F>(apiPath, pageToLoad, filter, sort);
    },
    update(id: string, dtoUpdate: U): Promise<void> {
      return doPut<void>(`${apiPath}/${id}`, dtoUpdate);
    },
  };
}

function toApiParams<F>(
  pageToLoad?: PageToLoad,
  filter?: F,
  sort?: SortAttribute[]
) {
  const params = {};

  if (filter) {
    // eslint-disable-next-line guard-for-in
    for (const property in filter) {
      const value = filter[property];
      set(params, property, Array.isArray(value) ? value.join() : value);
    }
  }

  if (sort) {
    const sAttributes = sort.map((sortAttribute) =>
      sortAttributeToParam(sortAttribute)
    );
    set(params, "sort", sAttributes);
  }
  if (pageToLoad) {
    // api page start from 0
    set(params, "page", pageToLoad.page - 1);
    set(params, "size", pageToLoad.size);
  }

  return params;
}

function sortAttributeToParam(sortAttribute: SortAttribute) {
  const order = sortAttribute.sort || "asc";

  return `${sortAttribute.key},${order}`;
}

function getAll<T, F>(
  apiPath: string,
  pageToLoad?: PageToLoad,
  filter?: F,
  sort?: SortAttribute[]
) {
  return doGet<Page<T>>(apiPath, {
    pageable: true,
    params: toApiParams(pageToLoad, filter, sort),
  });
}

function getFull<T, F>(
  apiPath: string,
  pageToLoad?: PageToLoad,
  filter?: F,
  sort?: SortAttribute[]
) {
  return doGet<T[]>(apiPath, {
    pageable: false,
    params: toApiParams(pageToLoad, filter, sort),
  });
}

export interface GetApi<T, F> {
  get: (id: string) => Promise<T>;
  getAll: (
    pageToLoad?: PageToLoad,
    filter?: F,
    sort?: SortAttribute[]
  ) => Promise<T[]>;
}

export function createGetApi<T, F>(apiPath: string): GetApi<T, F> {
  return {
    get(id: string): Promise<T> {
      return doGet<T>(`${apiPath}/${id}`);
    },
    getAll(
      pageToLoad?: PageToLoad,
      filter?: F,
      sort?: SortAttribute[]
    ): Promise<T[]> {
      return getFull<T, F>(apiPath, pageToLoad, filter, sort);
    },
  };
}
