import React, { lazy } from "react";

import { HashRouter, Route, Switch } from "react-router-dom";

import RoutesConstants from "../../enums/RoutesConstants";
import PrivateRoute from "./PrivateRoute";

const Login = lazy(() => import("../login/Login"));
const EmailVerify = lazy(() => import("../login/EmailVerity"));
const UserRegistration = lazy(
  () => import("../user-registration/UserRegistration")
);
const Layout = lazy(() => import("../layout/Layout"));
const OpenLayout = lazy(() => import("../open-layout/OpenLayout"));
const Error404 = lazy(() => import("../error/Error404"));
const PasswordRest = lazy(() => import("../login/PasswordReset"));
const PasswordChange = lazy(() => import("../login/PasswordChange"));

function AppRouter(): JSX.Element {
  return (
    <HashRouter>
      <Switch>
        <PrivateRoute path={RoutesConstants.LAYOUT} component={Layout} />
        <Route path={RoutesConstants.OPEN_LAYOUT} component={OpenLayout} />
        <Route path={RoutesConstants.LOGIN} component={Login} />
        <Route path={RoutesConstants.EMAIL_VERIFY} component={EmailVerify} />
        <Route path={RoutesConstants.PASSWORD_RESET} component={PasswordRest} />
        <Route
          path={RoutesConstants.PASSWORD_CHANGE}
          component={PasswordChange}
        />
        <Route
          path={RoutesConstants.USER_REGISTRATION}
          component={UserRegistration}
        />
        <Route path={RoutesConstants.ROOT_ROUTE} component={Login} exact />
        <Route component={Error404} />{" "}
        {/* TODO use some login-like page with app logo */}
      </Switch>
    </HashRouter>
  );
}

export default AppRouter;
