import React, { useEffect } from "react";

import { Redirect, Route } from "react-router-dom";

import { doAuthenticate, doLoadProfile } from "../../context/auth/AuthAction";
import RoutesConstants from "../../enums/RoutesConstants";
import useAuthState from "../../hooks/auth";

const PrivateRoute = ({ ...routeProps }) => {
  const { state, dispatch } = useAuthState();

  // try to authenticate for private path
  useEffect(() => {
    if (!state.authenticationState) {
      doAuthenticate(dispatch).then(() => {
        if (!state.user) {
          doLoadProfile(dispatch);
        }
      });
    }
  }, [state.authenticationState, state.user]);

  // console.info('private route path: ' + routeProps.path)
  // console.info(`private route authenticationState: ${authenticationState}`);

  // TODO whole route is called 2 times, or 2 times rerendered and warning is 2 times displayed ...

  if (state.authenticationState === "notAuthenticated") {
    // toast.warning(t('app.notAuthenticated'))
    console.info("redirecting to login page");
    // redirect to login page
    return <Redirect to={RoutesConstants.ROOT_ROUTE} />;
  }
  if (state.authenticationState === "authenticated") {
    // if is authenticated, allow access to the private route
    return <Route {...routeProps} />;
  }

  return <></>;
};

export default PrivateRoute;
