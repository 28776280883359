import React, { Dispatch } from "react";

import { AuthAction } from "../context/auth/AuthAction";
// eslint-disable-next-line import/no-named-as-default
import AuthStateContext from "../context/auth/AuthContext";
import { AuthState } from "../context/auth/AuthReducer";

function useAuthState(): { state: AuthState; dispatch: Dispatch<AuthAction> } {
  const context = React.useContext(AuthStateContext);

  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }

  return context;
}

export default useAuthState;
