import React from "react";

import classNames from "classnames";

import { colors } from "../../enums/colors";
import Loader from "./Loader";

interface PageLoaderProperties {
  size?: number;
  dark?: boolean;
}

function PageLoader({ size, dark }: PageLoaderProperties) {
  return (
    <div className={classNames("page-loader", dark && "dark")}>
      <Loader color={colors.PRIMARY} size={size} />
    </div>
  );
}

PageLoader.defaultProps = {
  size: 32,
  dark: false,
};

export default PageLoader;
