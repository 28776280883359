import { createCRUDApi, CRUDApi } from "./apiCalls";
import CompanyDto from "./dto/company/CompanyDto";
import CompanyDtoUpdate from "./dto/company/CompanyDtoUpdate";

export interface CompanyApiFilter {
  customers: string | string[] | undefined;
}

export default function createCompanyApi(): CRUDApi<
  CompanyDto,
  CompanyDtoUpdate,
  CompanyApiFilter
> {
  return createCRUDApi<CompanyDto, CompanyDtoUpdate, CompanyApiFilter>(
    "v1/companies"
  );
}
