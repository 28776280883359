import { createCRUDApi, CRUDApi } from "./apiCalls";
import LevelDto from "./dto/level/LevelDto";
import LevelDtoUpdate from "./dto/level/LevelDtoUpdate";

export interface LevelApiFilter {
  customers: string | string[] | undefined;
}

export default function createLevelApi(): CRUDApi<
  LevelDto,
  LevelDtoUpdate,
  LevelApiFilter
> {
  return createCRUDApi<LevelDto, LevelDtoUpdate, LevelApiFilter>("v1/levels");
}
