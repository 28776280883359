import AuthDto from "./dto/auth/AuthDto";
import AuthResponse from "./dto/auth/AuthResponse";
import RefreshResponse from "./dto/auth/RefreshResponse";
import { doPost } from "./restApi";

export default interface AuthApi {
  login: (dto: AuthDto) => Promise<AuthResponse>;
  logout: () => Promise<void>;
  refresh: () => Promise<RefreshResponse>;
}

export function createAuthApi(): AuthApi {
  return {
    logout: () => doPost("v1/logout", undefined),
    refresh: () => doPost<RefreshResponse>("/v1/auth/refresh", {}),
    login: (dto) => doPost<AuthResponse>("/v1/auth/authenticate", dto),
  };
}
