import React, { Dispatch } from "react";

import { AuthAction } from "./AuthAction";
import { AuthState, defaultAuthState } from "./AuthReducer";

export const AuthStateContext = React.createContext<{
  state: AuthState;
  dispatch: Dispatch<AuthAction>;
}>({ state: defaultAuthState, dispatch: () => null });

export default AuthStateContext;
