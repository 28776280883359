import AuthResponse from "../../api/dto/auth/AuthResponse";
import LevelDto from "../../api/dto/level/LevelDto";
import { Language } from "../../enums/language";
import { AuthenticationState, SidebarView } from "../../enums/types";
import Company from "../../model/Company";
import CurrentUser from "../../model/CurrentUser";
import { AuthAction, AuthActionType } from "./AuthAction";

export interface AuthState {
  user?: CurrentUser;
  language?: Language;
  authenticationState: AuthenticationState | undefined;
  loggedIn: boolean;
  loginError: boolean;
  loginExecuting: boolean;
  initialized: boolean;
  authResponse?: AuthResponse;
  selectedCustomerId?: string;
  selectedCompanyId?: string;
  selectedLevelId?: string;
  selectedHistoryDate?: Date;
  sidebarView: SidebarView;
  companies: Company[];
  levels: LevelDto[];
  errorMessage?: string;
}

export const defaultAuthState: AuthState = {
  user: undefined,
  language: undefined,
  authenticationState: undefined,
  loggedIn: false,
  loginError: false,
  loginExecuting: false,
  initialized: false,
  selectedCompanyId: undefined,
  selectedCustomerId: undefined,
  selectedLevelId: undefined,
  selectedHistoryDate: undefined,
  sidebarView: "open",
  levels: [],
  companies: [],
};

export const AuthReducer = (
  initialState: AuthState,
  action: AuthAction
): AuthState => {
  // logger.debug("AUTH Action: ", action);
  console.debug("AUTH Action: ", action);
  switch (action.type) {
    case AuthActionType.LOGIN_REQUEST:
      return {
        ...initialState,
        loginExecuting: true,
        loggedIn: false,
      };

    case AuthActionType.LOGIN_SUCCESS:
      return {
        ...initialState,
        authResponse: action.payload?.authResponse,
        loginExecuting: false,
        loginError: false,
        authenticationState: "authenticated",
      };

    case AuthActionType.LOGIN_ERROR:
      return {
        ...initialState,
        loginExecuting: false,
        authenticationState: "notAuthenticated",
        loginError: true,
        loggedIn: false,
      };

    case AuthActionType.REFRESH_TOKEN_SUCCESS:
      return {
        ...initialState,
        authenticationState: "authenticated",
      };

    case AuthActionType.REFRESH_TOKEN_ERROR:
      return {
        ...initialState,
        authenticationState: "notAuthenticated",
      };

    case AuthActionType.AUTHENTICATE_SUCCESS:
      return {
        ...initialState,
      };

    // case AuthActionType.PROFILE_REQUEST:
    //   return {
    //     ...initialState,
    //     loading: true,
    //     initialized: false,
    //   };
    //
    case AuthActionType.PROFILE_SUCCESS:
      return {
        ...initialState,
        user: action.payload?.user,
        selectedCompanyId: action.payload?.selectedCompanyId,
        selectedCustomerId: action.payload?.selectedCustomerId,
        selectedLevelId: action.payload?.selectedLevelId,
        levels: action.payload?.levels || [],
        companies: action.payload?.companies || [],
        selectedHistoryDate: action.payload?.selectedHistoryDate,
        loggedIn: true,
      };

    case AuthActionType.LANGUAGE: {
      return {
        ...initialState,
        language: action.payload?.language,
      };
    }

    case AuthActionType.COMPANY: {
      return {
        ...initialState,
        selectedCompanyId: action.payload?.selectedCompanyId,
      };
    }

    case AuthActionType.CUSTOMER: {
      return {
        ...initialState,
        selectedCustomerId: action.payload?.selectedCustomerId,
      };
    }

    case AuthActionType.LEVEL: {
      return {
        ...initialState,
        selectedLevelId: action.payload?.selectedLevelId,
      };
    }

    case AuthActionType.HISTORY_DATE: {
      return {
        ...initialState,
        selectedHistoryDate: action.payload?.selectedHistoryDate,
      };
    }

    case AuthActionType.SIDEBAR: {
      return {
        ...initialState,
        sidebarView: action.payload?.sidebarView || "open",
      };
    }

    case AuthActionType.PROFILE_ERROR:
      return {
        ...initialState,
        authenticationState: "notAuthenticated",
        errorMessage: action?.error || "",
        loggedIn: false,
      };

    case AuthActionType.LOGOUT:
      return {
        ...initialState,
        user: undefined,
        authenticationState: "notAuthenticated",
        loggedIn: false,
      };

    case AuthActionType.INITIALIZE_LANGUAGE_SUCCESS:
      return {
        ...initialState,
        initialized: true,
        language: action.payload?.language,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
