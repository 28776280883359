import axios, { AxiosResponse, ResponseType } from "axios";
import { set } from "lodash";
import qs from "qs";

const RestApiErrorName = "RestApiError";
const restApiError = (msg: string) => {
  const e = new Error(msg);
  e.name = RestApiErrorName;
  return e;
};

interface AxiosOptions {
  contentType: string;
  // includeToken: boolean,
  // token?: string,
  // includeInstallation: boolean,
  // includeLang: boolean,
  params?: any;
  onUploadProgress?: (event: any) => void;
}

const defaultAxiosOptions: AxiosOptions = {
  contentType: "application/json",
  // includeInstallation: true,
  // includeLang: true,
  // includeToken: true,
  // params?:,
};

interface PutPostOptions extends AxiosOptions {
  responseType: ResponseType;
}

const defaultPutPostOptions: PutPostOptions = {
  ...defaultAxiosOptions,
  responseType: "json",
  // returnRawData: false,
};

// TODO pageable vymysliet
const defaultGetOptions: GetOptions = {
  ...defaultPutPostOptions,
  pageable: false,
  pageSize: 999,
  page: 0,
};

interface GetOptions extends PutPostOptions {
  pageable: boolean;
  pageSize: number;
  page: number;
}

type DeleteOptions = AxiosOptions;
type PatchOptions = AxiosOptions;

const defaultDeleteOptions = defaultAxiosOptions;

const prepareGenericHeadersAndParams = (opts: AxiosOptions) => {
  const headers = {};
  if (opts.contentType) {
    set(headers, "Content-Type", opts.contentType);
  }
  // if (opts.includeToken) {
  //   set(headers, 'Authorization', 'Bearer ' + (opts.token || _accessToken))
  // }
  // if (opts.includeLang) {
  //   set(headers, 'Language', _language)
  // }
  //
  const params = {};

  console.info(`opts: ${JSON.stringify(opts)}`);

  // if (opts.includeInstallation) {
  //   const selectedInstallation = _installationId
  //   if (selectedInstallation) {
  //     set(params, 'installations', selectedInstallation)
  //   }
  // }

  return { headers, params };
};

function processGetPutPostResponse<T>(
  res: AxiosResponse<T>,
  url: string,
  method: string,
  opts: PutPostOptions | GetOptions
) {
  // if (opts.returnRawData) {
  // console.log(`rest api :: or raw response :: ${method} :: ${url}`, res)
  return res.data as T;
  // }
  // TODO paged response
  // if (res.data) {
  //   // console.log(`rest api :: ok response with data :: ${method} :: ${url}`, res)
  //   const dataResponse = res.data as IDataResponse<T>
  //   if (dataResponse) {
  //     if (dataResponse.infoMessages && dataResponse.infoMessages.length > 0) {
  //       // console.log(`rest api :: info from server :: ${method} :: ${url} :: ${dataResponse.infoMessages.join(' :: ')}`)
  //     }
  //     if (dataResponse.errorMessages && dataResponse.errorMessages.length > 0) {
  //       console.error(`rest api :: errors from server ::: ${method} :: ${url} :: ${dataResponse.errorMessages.join(' :: ')}`)
  //       // throw new Error('error messages received from server: ' + dataResponse.errorMessages.join(', '))
  //     }
  //     return dataResponse.data
  //   }
  // }
  // console.error(
  //   `rest api :: ok response, but data is missing :: ${method} :: ${url}`,
  //   res
  // );
  // throw restApiError(`no data received :: ${method} :: ${url}`);
}

function processDeleteResponse(res: AxiosResponse, url: string) {
  if (res) {
    // console.log(`rest api :: ok raw response :: DELETE :: ${url}`, res)
    return res.data as void;
  }
  throw restApiError(`no data received :: DELETE :: ${url}`);
}

function processPatchResponse(res: AxiosResponse, url: string) {
  if (res) {
    // console.log(`rest api :: ok raw response :: DELETE :: ${url}`, res)
    return res.data as void;
  }
  throw restApiError(`no data received :: PATCH :: ${url}`);
}

function processResponseError<T>(
  error: any,
  url: string,
  method: string,
  opts: PutPostOptions | GetOptions | DeleteOptions
) {
  if (error === null || error === undefined) {
    // console.log(`rest api :: strange response ! :: catched error, but it is null or undefined :: returning empty object :: ${method} :: ${url}`)
    return {} as T;
  }
  console.error(`rest api :: error :: ${method} :: ${url} ::`, error);
  throw error;
}

export function doGet<T>(
  url: string,
  options?: Partial<GetOptions>
): Promise<T> {
  const opts = options
    ? { ...defaultGetOptions, ...options }
    : defaultGetOptions;

  const { headers, params } = prepareGenericHeadersAndParams(opts);

  if (opts.pageable) {
    set(params, "page", opts.page);
    set(params, "size", opts.pageSize);
  }

  return axios
    .get<T>(url, {
      headers,
      params: { ...params, ...opts.params },
      paramsSerializer: (parameters) => {
        return qs.stringify(parameters, { arrayFormat: "repeat" });
      },
      responseType: opts.responseType,
      onUploadProgress: opts.onUploadProgress,
      withCredentials: true,
    })
    .then((res) => processGetPutPostResponse(res, url, "GET", opts))
    .catch((error) => processResponseError(error, url, "GET", opts));
}

export function doPost<T>(
  url: string,
  data: any,
  options?: Partial<PutPostOptions>
): Promise<T> {
  const opts = options
    ? { ...defaultPutPostOptions, ...options }
    : defaultPutPostOptions;

  const { headers, params } = prepareGenericHeadersAndParams(opts);

  return axios
    .post<T>(url, data, {
      headers,
      params: { ...params, ...opts.params },
      responseType: opts.responseType,
      onUploadProgress: opts.onUploadProgress,
      withCredentials: true,
    })
    .then((res) => processGetPutPostResponse(res, url, "POST", opts))
    .catch((error) => processResponseError(error, url, "POST", opts));
}

export function doPut<T>(
  url: string,
  data: any,
  options?: Partial<PutPostOptions>
): Promise<T> {
  const opts = options
    ? { ...defaultPutPostOptions, ...options }
    : defaultPutPostOptions;

  const { headers, params } = prepareGenericHeadersAndParams(opts);

  return axios
    .put<T>(url, data, {
      headers,
      params: { ...params, ...opts.params },
      responseType: opts.responseType,
      onUploadProgress: opts.onUploadProgress,
      withCredentials: true,
    })
    .then((res) => processGetPutPostResponse(res, url, "PUT", opts))
    .catch((error) => processResponseError(error, url, "PUT", opts));
}

export function doDelete(
  url: string,
  options?: Partial<DeleteOptions>
): Promise<void> {
  const opts = options
    ? { ...defaultDeleteOptions, ...options }
    : defaultDeleteOptions;

  const { headers, params } = prepareGenericHeadersAndParams(opts);

  return axios
    .delete(url, {
      headers,
      params: { ...params, ...opts.params },
      onUploadProgress: opts.onUploadProgress,
      withCredentials: true,
    })
    .then((res) => processDeleteResponse(res, url))
    .catch((error) => processResponseError(error, url, "DELETE", opts));
}

export function doPatch<T>(
  url: string,
  data?: any,
  options?: Partial<PatchOptions>
): Promise<void> {
  const opts = options
    ? { ...defaultDeleteOptions, ...options }
    : defaultDeleteOptions;

  const { headers, params } = prepareGenericHeadersAndParams(opts);

  return axios
    .patch<T>(url, data, {
      headers,
      params: { ...params, ...opts.params },
      onUploadProgress: opts.onUploadProgress,
      withCredentials: true,
    })
    .then((res) => processPatchResponse(res, url))
    .catch((error) => processResponseError(error, url, "PATCH", opts));
}
